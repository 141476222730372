<template>
	<div class="contentArea">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'ContentAreaVue'
	};
</script>

<style>	
	.contentArea {
		padding: var(--spacing-large);
		width: calc( 100% - calc(var(--spacing-large) * 2))
	}
	.contentArea > .contentArea {
		/* padding: var(--spacing); */
		width: calc( 100% - calc(var(--spacing-large) * 2))
	}
	.contentArea.light {
		background-color: var(--el-color-white); 
	}

	.contentArea h4 {
		border-bottom: 1px solid var(--el-color-primary-light-8);
	}
	.contentArea>* {
		margin-top: 0;
	}
	.contentArea > .contentArea:not(.bleeding):first-of-type {
		padding-top: calc(var(--spacing-large) * 2);
	}

	/*
		Content área que não é a última do tipo
		terá sua última content área sem padding,
		desde que essa content área filha não seja
		a primeira do tipo
	*/
	.contentArea:not(:last-of-type) > .contentArea:last-of-type:not(:first-of-type) {
		padding-bottom: 0;
	}

	.contentArea.wide {
		padding-left:0;
		padding-right:0;
		width: 100%;
	}
	.contentArea.wide>* {
		padding-left:var(--spacing-large);
		padding-right:var(--spacing-large);
	}

	.contentArea .bleeding {
		width: 100%;
		padding-left:0;
		padding-right:0;
	}
</style>
