export class GlobalSettingsConstants {
    static ApplicationDefaultSettings = {
        PREFIX_KEY: 'Application',
        SHOULD_ALLOW_USE_NEW_EDITOR_KEY: "Antevemus.Settings.Application.ShouldAllowUseNewEditor",
        NEW_EDITOR_APIKEY_KEY: "Antevemus.Settings.Application.NewEditorApiKey",
        NEW_EDITOR_LICENSEKEY_KEY: "Antevemus.Settings.Application.NewEditorLicenseKey",
        NEW_EDITOR_DEFAULT_LANGUAGE_KEY: "Antevemus.Settings.Application.NewEditorDefaultLanguage",
        META_EMAIL_DISABLED_EVENTS_KEY: "Antevemus.Settings.Application.MetaEmail.DisabledEvents",
        MENU_WARNING_TEXT_KEY: "Antevemus.Settings.Application.MenuWarningText",
        Caching: {
            SHOULD_BYPASS_CACHE_KEY: "Antevemus.Settings.Caching.ShouldByPass",
        },
        Files: {
            FILE_ALLOWED_EXTENSIONS_KEY: "Antevemus.Settings.Files.AllowedExtensions",
            FILE_MAX_FILE_SIZE_KEY: "Antevemus.Settings.Files.MaxFileSize",
        },
        Department: {
            DEPARTMENT_SHOULD_SHOW_INITIAL_ATTENDANCE_KEY: "Antevemus.Settings.Department.ShouldShowInitialAttendanceDepartment",
            DEPARTMENT_INITIAL_ATTENDANCE_DEPARTMENT_LABEL_KEY: "Antevemus.Settings.Department.InitialAttendanceDepartmentLabel",
        },
        Process: {
            PROCESS_MAX_DAYS_FOR_SEARCH_RESULT_KEY: "Antevemus.Settings.Process.MaxDaysForSearchResult",
            PROCESS_ALLOWED_ROLES_FOR_REQUESTER_EDIT_KEY: "Antevemus.Settings.Process.AllowedRolesForRequesterEdit",
        },
        Authorization: {
            EXTERNAL_ROLES_KEY: "Antevemus.Settings.Authorization.ExternalRoles",
            SPECIAL_ROLES_KEY: "Antevemus.Settings.Authorization.SpecialRoles",
            GODMODE_ROLE_KEY: "Antevemus.Settings.Authorization.GodmodeRole",
            MANAGER_ROLE_PREFIX_KEY: "Antevemus.Settings.Authorization.ManagerRolePrefix",
            ANALYST_ROLE_PREFIX_KEY: "Antevemus.Settings.Authorization.AnalystRolePrefix",
            LAWYER_ROLE_KEY: "Antevemus.Settings.Authorization.LawyerRole",
            API_ROLE_KEY: "Antevemus.Settings.Authorization.ApiRoles",
        },
        Common: {
            GUID_EMPTY: '00000000-0000-0000-0000-000000000000',
            TRUE_LITERAL: 'True',
            FALSE_LITERAL: 'False',
            ASSUMIR_PROCESSSO: '_0',
		    ATRIBUIR_PROCESSSO: '_1',
		    TIPO_HISTORICO_PROCESSSO_ATENDIMENTO: '1',
		    TIPO_HISTORICO_PROCESSSO_SOLICITACAO: '2',
        },
    };
}
